import React from "react"
import { graphql, Link } from "gatsby"
import ReactPlayer from "react-player"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/Button"

const FinalistPage = ({ data }) => {
  const result = data.allSitePage.edges[0].node.context
  console.log(result)
  return (
    <Layout>
      <SEO title={`Finalist - ${result.namaTim}`} />
      <div
        className="container mx-auto my-12 flex flex-col items-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="w-full flex justify-between">
          <Link
            to={`/finalist/${result.type}/${
              (parseInt(result.id) - 1) % 10 || 10
            }`}
          >
            <p className="mx-4 lg:mx-8 text-black text-xs lg:text-sm">
              &#60;&#60; Prev
            </p>
          </Link>
          <Link
            to={`/finalist/${result.type}/${(parseInt(result.id) % 10) + 1}`}
          >
            <p className="mx-4 lg:mx-8 text-black text-xs lg:text-sm">
              Next &#62;&#62;
            </p>
          </Link>
        </div>
        <div className="flex my-4 mx-auto text-xl lg:text-4xl text-center font-bold text-green-500">
          {result.namaTim}
        </div>
        <div className="mt-4 mb-8 mx-4 lg:mx-8 md:w-5/12">
          <ReactPlayer
            url={result.videoPrototype}
            controls={true}
            width="100%"
          />
        </div>
        <div className="flex m-4 lg:w-3/4 text-md lg:text-2xl text-center font-bold">
          {result.judul}
        </div>
        <div className="flex m-4 lg:w-3/4 text-sm lg:text-lg text-justify">
          {result.deskripsiSingkat}
        </div>
        <div className="flex items-center justify-center m-4 w-1/2 h-1/2">
          <img className="" src={result.fotoProduk} alt="foto produk" />
        </div>
        <div className="flex flex-col lg:flex-row m-8 lg:w-3/4 justify-between gap-12">
          <div className="grid grid-cols-1 text-sm lg:text-lg">
            <p>{result.namaTim}</p>
            <p>
              Perguruan Tinggi:{" "}
              <span className="font-bold">{result.perguruanTinggi}</span>
            </p>
            <p>
              Ketua: <span className="font-bold">{result.namaKetua}</span>
            </p>
            <p>
              Anggota 1:{" "}
              <span className="font-bold">{result.namaAnggota1}</span>
            </p>
            <p>
              Anggota 2:{" "}
              <span className="font-bold">{result.namaAnggota2}</span>
            </p>
          </div>
          <div className="flex justify-center items-center flex-col gap-4">
            <a href={result.poster} target="_blank" rel="norefferer">
              <Button className="text-white bg-red-500 hover:bg-red-700">
                Lihat Poster
              </Button>
            </a>
            <a
              href={
                result.type === "university"
                  ? "http://bit.ly/FavTeamPT"
                  : "http://bit.ly/FavTeamSMASMK"
              }
              target="_blank"
              rel="norefferer"
            >
              <Button className="text-white bg-green-500 hover:bg-green-700">
                Vote Sekarang
              </Button>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            id
            namaTim
            namaKetua
            namaAnggota1
            namaAnggota2
            perguruanTinggi
            poster
            videoPrototype
            judul
            deskripsiSingkat
            fotoProduk
            type
          }
        }
      }
    }
  }
`

export default FinalistPage
