import React from "react"

const Button = ({ children, injectedOnClick, className, ...props }) => (
  <button
    className={`rounded-sm py-3 px-6 font-bold font-montserrat ${className}`}
    onClick={injectedOnClick}
    {...props}
  >
    {children}
  </button>
)

export default Button
